<template>
  <div class="container-fluid">
    <div class="row" v-if="hamla == ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="col-12 col-lg-6 g text-right" v-if="hamla_1">
            <button class="btn btn-lg btn-primary" @click="hamla = '1'; getBusses()">
                البشائر
            </button>
        </div>
        <div class="col-12 col-lg-6 g text-left" v-if="hamla_2">
            <button class="btn btn-lg btn-success" @click="hamla = '2'; getBusses()">
                الميسر
            </button>
        </div>
    </div>
    <div class="row" v-if="hamla != ''">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>
                        الحافلات
                        (
                            {{ hamla == '1' ? 'البشائر' : 'الميسر' }}
                        )
                        <button class="btn btn-primary" v-b-modal.add style="padding: 10px; padding-top: 8px; padding-right: 8px; border-radius: 50%; height: 30px; width: 30px;">
                            <i class="fa fa-plus"></i>
                        </button>
                    </h5>
                </div>
                <div class="card-footer">
                    <div class="col-12 table-responsive">
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <th>
                                    العنوان/رقم الحافلة
                                </th>
                                <th>
                                    نقطة الانطلاق
                                </th>
                                <th>
                                    رقم اللوحة
                                </th>
                                <th>
                                    السائق
                                </th>
                                <th>
                                    المساعد
                                </th>
                                <th>
                                    الدليل
                                </th>
                                <th>
                                    النوع
                                </th>
                                <th>
                                    خيارات
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="bus in busses" :key="bus._id">
                                    <td>
                                        {{ bus.title }}
                                    </td>
                                    <td>
                                        {{ bus.start_location }}
                                    </td>
                                    <td>
                                        {{ bus.plate_number }}
                                    </td>
                                    <td>
                                        {{ bus.driver_name }}
                                    </td>
                                    <td>
                                        {{ bus.helper_name }}
                                    </td>
                                    <td>
                                        {{ bus.guide_name }}
                                    </td>
                                    <td>
                                        {{ bus.mix ? 'عوائل' : 'رجال' }}
                                    </td>
                                    <td>
                                        <b-dropdown dropleft style="width: 100%;"
                                            id="dropdown-1x"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            text="خيارات"
                                            variant="secondary"
                                            size="sm"
                                        >
                                            <b-dropdown-item @click="current = bus" v-b-modal.edit>
                                                <i class="fa fa-edit"></i>
                                                تعديل الحافلة
                                            </b-dropdown-item>
                                            <b-dropdown-item style="font-size: 12px" @click="deleteUser(bus._id)">
                                                <i class="fa fa-trash"></i>
                                                حذف الحافلة
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="add" title="اضافة حافلة" hide-footer>
        <div class="form-group">
          <label for="">
            العنوان/رقم الباص
          </label>
          <input type="text"
            class="form-control" v-model="create.title">
        </div>
        <div class="form-group">
          <label for="">
            نقطة الانطلاق
          </label>
          <select class="form-control" name="" v-model="create.start_location" id="">
            <option :value="i" v-for="i in locations" :key="i">{{ i }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">
            رقم اللوحة
          </label>
          <input type="text"
            class="form-control" v-model="create.plate_number">
        </div>
        <div class="form-group">
          <label for="">
            رقم الشاسية
          </label>
          <input type="text"
            class="form-control" v-model="create.chassis_number">
        </div>
        <div class="form-group">
          <label for="">
            تفاصيل/ملاحظات
          </label>
          <input type="text"
            class="form-control" v-model="create.details">
        </div>
        <div class="form-group">
          <label for="">
            السائق
          </label>
          <input type="text"
            class="form-control" v-model="create.driver_name">
        </div>
        <div class="form-group">
          <label for="">
            جوال السائق
          </label>
          <input type="text"
            class="form-control" v-model="create.driver_phone">
        </div>
        <div class="form-group">
          <label for="">
            المساعد
          </label>
          <input type="text"
            class="form-control" v-model="create.helper_name">
        </div>
        <div class="form-group">
          <label for="">
            جوال المساعد
          </label>
          <input type="text"
            class="form-control" v-model="create.helper_phone">
        </div>
        <div class="form-group">
          <label for="">
            الدليل
          </label>
          <input type="text"
            class="form-control" v-model="create.guide_name">
        </div>
        <div class="form-group">
          <label for="">
            جوال الدليل
          </label>
          <input type="text"
            class="form-control" v-model="create.guide_phone">
        </div>
        <div class="form-group">
          <label for="">
            اللوكيشن
          </label>
          <input type="text"
            class="form-control" v-model="create.tracking_location">
        </div>
        <div class="form-group">
          <label for="">
            الحد الاقصى للحجاج في الحافلة
          </label>
          <input type="number"
            class="form-control" v-model="create.limit">
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" :value="false" v-model="create.mix">
            رجال فقط
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" :value="true" v-model="create.mix">
            عوائل
          </label>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="createNow()">
                اضافة الحافلة
            </button>
        </div>
    </b-modal>
    <b-modal id="edit" title="تعديل " hide-footer>
        <template v-if="current._id">
            <div class="form-group">
            <label for="">
                العنوان/رقم الباص
            </label>
            <input type="text"
                class="form-control" v-model="current.title">
            </div>
        <div class="form-group">
          <label for="">
            نقطة الانطلاق
          </label>
          <select name="" class="form-control" v-model="current.start_location" id="">
            <option :value="i" v-for="i in locations" :key="i">{{ i }}</option>
          </select>
        </div>
            <div class="form-group">
            <label for="">
                رقم اللوحة
            </label>
            <input type="text"
                class="form-control" v-model="current.plate_number">
            </div>
            <div class="form-group">
            <label for="">
                رقم الشاسية
            </label>
            <input type="text"
                class="form-control" v-model="current.chassis_number">
            </div>
            <div class="form-group">
            <label for="">
                تفاصيل/ملاحظات
            </label>
            <input type="text"
                class="form-control" v-model="current.details">
            </div>
            <div class="form-group">
            <label for="">
                السائق
            </label>
            <input type="text"
                class="form-control" v-model="current.driver_name">
            </div>
            <div class="form-group">
            <label for="">
                جوال السائق
            </label>
            <input type="text"
                class="form-control" v-model="current.driver_phone">
            </div>
            <div class="form-group">
            <label for="">
                المساعد
            </label>
            <input type="text"
                class="form-control" v-model="current.helper_name">
            </div>
            <div class="form-group">
            <label for="">
                جوال المساعد
            </label>
            <input type="text"
                class="form-control" v-model="current.helper_phone">
            </div>
            <div class="form-group">
            <label for="">
                الدليل
            </label>
            <input type="text"
                class="form-control" v-model="current.guide_name">
            </div>
            <div class="form-group">
            <label for="">
                جوال الدليل
            </label>
            <input type="text"
                class="form-control" v-model="current.guide_phone">
            </div>
        <div class="form-group">
          <label for="">
            اللوكيشن
          </label>
          <input type="text"
            class="form-control" v-model="current.tracking_location">
        </div>
            <div class="form-group">
            <label for="">
                الحد الاقصى للحجاج في الحافلة
            </label>
            <input type="number"
                class="form-control" v-model="current.limit">
            </div>
            <div class="form-check">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" :value="false" v-model="current.mix">
                رجال فقط
            </label>
            </div>
            <div class="form-check">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" :value="true" v-model="current.mix">
                عوائل
            </label>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="save()">
                    تعديل الحافلة
                </button>
            </div>
        </template>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            current: {},
            busses: [],
            user: JSON.parse(localStorage.getItem("user")),
            create: {
                mix: false
            },
            hamla: "",
            hamla_2: checkPer("hamla_2"),
            hamla_1: checkPer("hamla_1"),
            locations: []
        }
    },
    created(){
        if(!checkPer('busses')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        $.post(api + '/admin/web/start-locations', {
            jwt: g.user.jwt,
            hamla: g.hamla
        }).then(function(r){
            g.locations = r.response;
        })
    },
    methods: {
        getBusses(){
            var g = this;
            $.post(api + '/admin/busses/list', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.busses = r.response;
            })
        },
        createNow(){
            var g = this;
            g.create.hamla = g.hamla
            $.post(api + '/admin/busses/add', {
                jwt: g.user.jwt,
                obj: g.create
            }).then(function(r){
                g.getBusses();
                alert("تم الاضافة بنجاح", 100)
            })
        },
        deleteUser(id){
            if(confirm("متأكد من حذف الحافلة؟")){
                var g = this;
                $.post(api + '/admin/busses/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(a){
                    g.getBusses();
                    alert("تم الحذف بنجاح", 100);
                })
            }
        },
        save(){
            var g = this;
            g.current.hamla = g.hamla
            $.post(api + '/admin/busses/edit', {
                jwt: g.user.jwt,
                id: g.current._id,
                obj: g.current
            }).then(function(a){
                g.getBusses();
                alert("تم التعديل بنجاح", 100);
            })
        }
    }
}
</script>

<style>
#dropdown-1x__BV_toggle_{
    padding: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-radius: 0px;
}
</style>